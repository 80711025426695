import { FinishNewsletterPopupProps } from '@husky-x/cc-finish-newsletter-popup/dist/types/models';

export const FinishProductDifferencesMock = {
  header: '<h2>ERFAHREN SIE WIE SICH UNSERE PRODUKTE UNTERSCHEIDEN</h2>',
  borderedImage: true,
  navigationButton: {
    link: '/produkte/',
    label: 'ALLE PRODUKTE ANSEHEN',
  },
  useCheckmark: false,
  tables: [
    {
      title: 'FINISH',
      legend:
        '<ul>\n<li><sup>*</sup>Bei stark verschmutztem Geschirr</li>\n<li><sup>**</sup>vs. Finish Power basierend auf dem Durchschnittsgewicht</li>\n<li><sup>***</sup>Inhaltsstoff: Enzym</li>\n</ul>',
      rows: [
        {
          text: 'Reinigung',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt82888abe65a3cf92/66a8bd75f24439cde173be08/clean.svg',
        },
        {
          text: 'Gland',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt4068f74db0e98470/66a8bdf6b8115e2482a37a72/shine.svg',
        },
        {
          text: 'Entfernt 48h Eingetrocknetes<sup>*</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt4b1a83960fae2f42/66a8bd75b8115e47cca37a6d/dried-on.svg',
        },
        {
          text: 'Fettlösekraft',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltef4110afe98d3440/66a8bdf6f24439951073be0f/tough-on-grease.svg',
        },
        {
          text: 'Kein Vorspülen',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltb35fc648c6362147/66a8bdf61097ec6bcaa86bf5/skip-the-rinse.svg',
        },
        {
          text: 'Ohne Auspacken',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt906d9e1af7cf7cf8/66a8bd7575e8676dacd73cd9/wrapper-free-tabs.svg',
        },
        {
          text: 'Schnellauflösend',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt84a3d1465f181c87/66a8bdf67da9b43d0cb481c0/fast-dissolving.svg',
        },
        {
          text: 'Weniger Chemikaliengehal<sup>**</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt0b422bd29369e3e4/66a8bdf6739577514719fa1a/less-chemical-weight.svg',
        },
        {
          text: 'Glasschutz',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blta20864530554004a/66a8bd751a892d24844a3ac8/glass-protection.svg',
        },
        {
          text: 'Verleiht mehr Frische',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt95a23ce6c566f108/66a8bd7517cd4f0b596f724a/freshness-boost.svg',
        },
        {
          text: 'Entfernt Eingebranntes effektiv',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltf4e3970c7de0d7ea/66a8bd7506435dc50cddea0b/burnt-on-stains.svg',
        },
        {
          text: 'Richtiger Inhaltsstoff<sup>***</sup> zur richtigen Zeit',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt4228905f1347b8d8/66a8bd75f095557978747345/right-ingredient-right-time-1.png',
        },
      ],
      columns: [
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt5a1d9e23a9f38b42/66a8bab8f2443983a573bdfc/ultimate-plus-x2.png',
          productName: 'ULTIMATE PLUS',
          productLink: '/produkte/reiniger/ultimate-plus-spuelmaschinentabs/48-regular/',
          color: 'gold-gradient',
          values: [
            {
              value: 5,
            },
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt885b71b3dca45c5a/66a8bd76b3baaf4f6397e363/ultimate-x2.png',
          productName: 'ULTIMATE',
          productLink: '/produkte/reiniger/ultimate-spulmaschinentabs/54-regular/',
          color: 'black-gradient',
          values: [
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 3,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt9a8d029b63113ce2/66a8bd75fb2f0f64f438eba8/quantum-x2.png',
          productName: 'QUANTUM',
          productLink: '/produkte/reiniger/quantum-spuelmaschinentabs/58-regular/',
          color: 'dark-blue-gradient',
          values: [
            {
              value: 3,
            },
            {
              value: 3,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltf7e1f373bd749614/66a8bd753d47313511294ec4/power-x2.png',
          productName: 'POWER',
          productLink: '/produkte/reiniger/power-spuelmaschinentabs/30-regular/',
          color: 'blue-gradient',
          values: [
            {
              value: 2,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
      ],
    },
  ],
};

export const FinishProductCardsMock = {
  cards: [
    {
      productName: 'FINISH ULTIMATE',
      image:
        'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltbe684d4182d585d0/66a8e0aafb2f0f096538ec1d/finish-ultimate.webp',
      cardStyle: 'dark-blue',
      button: {
        link: '/produkte/reiniger/ultimate-spuelmaschinentabs/54-regular/',
        text: 'ENTDECKEN SIE ULTIMATE',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltd1cced5b87a8da99/66a8e0aafd20b8f52f02207b/tough-on-grease-outline-red.webp',
          name: 'FETTLÖSE-KRAFT',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt17bfd79ff6231d4b/66a8e0a9fdec72853b5fee05/dried-on-stains-outline-red.webp',
          name: 'ENTFERNT <strong>48</strong>H EINGETROCKNETES',
          styleType: 'text-on-image',
        },
      ],
      mobileOrder: 2,
    },
    {
      productName: 'FINISH ULTIMATE PLUS',
      primary: true,
      image:
        'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltbba52ac79e9fa3b8/66a8e0aa6b12d6708efab797/finish-ultimate-plus.webp',
      cardStyle: 'black',
      button: {
        link: '/produkte/reiniger/ultimate-plus-spuelmaschinentabs/48-regular/',
        text: 'ENTDECKEN SIE UNSER BESTES, ULTIMATE PLUS',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt7d089cf277e6d47b/66a8e0a96ea8c533e9c94d69/extra-cleaning-power-outline-gold.webp',
          name: '<strong>3</strong>-FACH REINIGUNGS-KRAFT',
          styleType: 'text-on-image',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt5ee59c8130b9cd59/66a8e0aa7da9b4508ab48253/tough-on-grease-gold-outline-gold.webp',
          name: 'FETTLÖSE-KRAFT',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt383c425593ccd620/66a8e0aa7da9b4e8dfb48257/burnt-on-stains-outline-gold.webp',
          name: 'ENTFERNT EINGEBRANNTES EFFEKTIV',
        },
      ],
      mobileOrder: 1,
    },
    {
      productName: 'FINISH QUANTUM',
      image:
        'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt26b1433712bdce00/66a8e0aaa6d7811496351da4/finish-quantum.webp',
      cardStyle: 'light-blue',
      button: {
        link: '/produkte/reiniger/quantum-spuelmaschinentabs/58-regular/',
        text: 'ENTDECKEN SIE QUANTUM',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltd1cced5b87a8da99/66a8e0aafd20b8f52f02207b/tough-on-grease-outline-red.webp',
          name: 'FETTLÖSE-KRAFT',
        },
      ],
      mobileOrder: 3,
    },
  ],
};

export const FinishNewsletterPopupMock: FinishNewsletterPopupProps = {
  headline: 'NICHTS MEHR VERPASSEN',
  image: {
    src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt7512496341f5a468/66e40ec920174cb857ed2c1d/newsletter_popup_image.png',
    mobileSrc:
      'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt63c4941b86e10813/66e40ec9b866513c5e3d97ef/newsletter_popup_image_mobile.png',
    alt: 'finish',
  },
  thankYou: {
    headline: 'VIELEN DANK FÜR IHRE ANMELDUNG FÜR DEN FINISH NEWSLETTER!',
    description: '',
    closeButton: {
      label: 'schließen',
      redirectUrl: '/',
    },
  },
  form: {
    id: '7f4311c4-00e3-48f9-a9cb-13b20ef2b28f',
    url: 'https://phx-promoplatform-api-pl-prod.promoplatform.rbcloud.io/api/v1/external/forms',
    submitErrorMessage: 'Bitte füllen Sie die erforderlichen Felder aus.',
  },
};
